export enum CollectionsActions {
  GET_ALL_COLLECTIONS
}

export enum DevicesActions {
  GET_ALL_DEVICES,
  GET_DEVICE_DATA
}

export enum ErrorActions {
  SHOW_ERROR,
  HIDE_ERROR,
  SET_ERROR
}
