import React from 'react'
import { ShellProps } from '../src/types/shell'
import RouteDevicesTab from 'components/routeDevicesTab'
import ConfigProvider from 'context/config/ConfigProvider'
import CollectionsProvider from 'context/devices/CollectionsProvider'
import DevicesProvider from 'context/devices/DevicesProvider'
import projectNames from './configs/projectNames'
import { ThemeProvider, ToastProvider, useToast } from '@veneer/core'

declare global {
  interface Window {
    Shell: ShellProps
  }
}
export default function Root(props) {
  const { v1 } = window.Shell as ShellProps
  const stack = v1?.app?.getAuthStack()
  const events = v1?.events
  const localization = {
    language: v1?.localization?.language || 'en',
    country: v1?.localization?.country || 'US'
  }
  const navigation = v1?.navigation
  const themeProviderProps = v1?.theme?.getThemeProviderProperties?.()
  const mfeProps = { events, navigation }
  return (
    <section id={projectNames.packageJsonName}>
      <ThemeProvider {...themeProviderProps}>
        <ConfigProvider {...localization}>
          <DevicesProvider stack={stack} authProvider={v1.authProvider}>
            <CollectionsProvider stack={stack} authProvider={v1.authProvider}>
              {props.useToast ? (
                <RouteDevicesTab {...props} {...mfeProps} />
              ) : (
                <ToastProvider>
                  <RouteDevicesTab
                    {...props}
                    {...mfeProps}
                    useToast={useToast}
                  />
                </ToastProvider>
              )}
            </CollectionsProvider>
          </DevicesProvider>
        </ConfigProvider>
      </ThemeProvider>
    </section>
  )
}
