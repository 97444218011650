import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import DevicesList from 'components/devicesList'
import DeviceDetails from 'components/deviceDetails'

const RouteDevicesTab = (props) => {
  const { navigation, useToast } = props
  const baseurl =
    props.customRelativePath !== undefined
      ? props.customRelativePath
      : '/policies/devices'
  const baseName = navigation
    ? navigation.createHref({ pathname: baseurl })
    : baseurl

  const { addToast, toasts, updateToast } = useToast()
  const displayToaster = (
    toasterId,
    msg,
    msgType = 'positive',
    subTitle?: string
  ) => {
    if (
      toasts &&
      toasts.length > 0 &&
      toasts.findIndex((item) => item.id === toasterId) >= 0
    ) {
      updateToast(toasterId, {
        id: toasterId,
        text: msg,
        type: msgType,
        subtitle: subTitle || ''
      })
    } else {
      addToast({
        id: toasterId,
        type: msgType,
        text: msg,
        subtitle: subTitle || ''
      })
    }
  }

  const deviceProps = { ...props, baseurl, displayToaster }

  return (
    <Router basename={baseName}>
      <Switch>
        <Route exact path="/">
          <DevicesList {...deviceProps} />
        </Route>
        <Route path="/:deviceId">
          <DeviceDetails {...deviceProps} />
        </Route>
      </Switch>
    </Router>
  )
}

export default RouteDevicesTab
