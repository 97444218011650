import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const AccordionContent = styled.div`
  display: flex;
  padding: ${primitives.layout.size3}px;
`

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`
export const SideMenu = styled.div`
  position: sticky;
  top: 10px;
  height: 100%;
`
