import React, { useReducer, useContext } from 'react'
import DevicesContext from 'context/devices/DevicesContext'
import DevicesReducer from 'context/devices/DevicesReducer'
import { DevicesActions } from 'context/types'
import { FleetMgtSvcClient } from '@jarvis/web-stratus-client'
import { Stack } from '@jarvis/web-stratus-client'
import ConfigContext from 'context/config/configContext'
import { errorReducer } from 'context/ErrorReducer'

const limit = 500

const DevicesProvider = (props) => {
  const cContext = useContext(ConfigContext)
  const { t } = cContext
  const initialState = {
    devices: null,
    deviceData: null
  }
  const [state, dispatch] = useReducer(DevicesReducer, initialState)
  const stackFromShell = props.stack ? props.stack : Stack.dev

  const fleetMgtSvcClient = new FleetMgtSvcClient(
    stackFromShell,
    props.authProvider
  )

  const getDevicesByCollection = async (collectionId, displayToaster) => {
    displayDevices(null)
    try {
      let hasMore = false
      let offset = 0
      const items = []
      do {
        const res = await fleetMgtSvcClient.getAllDevicesByCollectionId(
          collectionId,
          offset++,
          limit
        )
        if (res?.status === 200 && res.data?.items) {
          items.push(...res.data.items)
          hasMore = res.data.items.length === limit
        } else {
          hasMore = false
        }
      } while (hasMore)
      displayDevices(items)
    } catch (error) {
      displayDevices([])
      const newError = errorReducer(error, t).error
      displayToaster(
        'displayDevices-toaster-id',
        newError.errorMessage,
        'negative'
      )
    }
  }

  const triggerAssessment = async (displayToaster, deviceId: string) => {
    try {
      const res = await fleetMgtSvcClient.triggerAssessments([deviceId])
      if (res?.status === 204) {
        displayToaster('triggerAssessment-toaster-id')
      }
    } catch (error) {
      const newError = errorReducer(error, t).error
      displayToaster(
        'triggerAssessment-toaster-id',
        newError.errorMessage,
        'negative'
      )
    }
  }

  const displayDevices = (payload) => {
    dispatch({ type: DevicesActions.GET_ALL_DEVICES, payload })
  }

  const getDeviceData = (payload) => {
    dispatch({ type: DevicesActions.GET_DEVICE_DATA, payload })
  }

  const getDeviceDetails = async (
    displayToaster,
    deviceId: string,
    redirect
  ) => {
    try {
      getDeviceData(null)
      const res = await fleetMgtSvcClient.getDevice(deviceId)
      if (res?.status === 200) {
        getDeviceData(res.data)
      }
    } catch (error) {
      getDeviceData({ policies: [] })
      const newError = errorReducer(error, t).error
      displayToaster('getDevice-toaster-id', newError.errorMessage, 'negative')
      redirect()
    }
  }

  return (
    <DevicesContext.Provider
      value={{
        devices: state.devices,
        deviceData: state.deviceData,
        getDevicesByCollection,
        getDeviceDetails,
        triggerAssessment
      }}
    >
      {props.children}
    </DevicesContext.Provider>
  )
}

export default DevicesProvider
