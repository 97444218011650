import { Group } from 'utils/Group'
import moment from 'moment'

export const RELOAD_EVENT = 'ecp-banner-reload-call'

export enum DATE_FORMAT {
  LAST_RUN = 'lll'
}

/**
 * Common table constant
 */

export const NO_DATA = '--'

/**
 * Get group name, localized if necessary
 *
 * @param group device group
 * @param t localization
 * @returns {string} group name: localized or user-defined
 */

export function getGroupName(group: Group, t): string {
  return ['All', 'Ungrouped'].includes(group.name) && group.builtIn === true
    ? t(`devices.group.${group.name.toLowerCase()}`)
    : group.name
}

/**
 * Find Predefined group
 *
 * @param groupArray array of groups
 * @param groupName group name
 * @returns {Group} group
 */
export function findPredefinedGroup(
  groupArray: Array<Group>,
  groupName: string
) {
  return groupArray.find(
    (obj) => obj.name === groupName && obj.builtIn === true
  )
}

/**
 * Format date/time in common format independently on current locale
 *
 * @param value specifies date/time to be localized
 * @param format value specifies display format
 * @returns {string} with localized date/time
 */
export function formatDate(value?: Date, format?: DATE_FORMAT): string {
  return value ? moment(value).format(format) : NO_DATA
}

/**
 * Parse JSON safely
 *
 * @param text JSON text string
 * @param defResult error result
 * @returns {object} parsed object
 */
export function jsonParse(text: string, defResult = []) {
  try {
    return text ? JSON.parse(text) : defResult
  } catch (SyntaxError) {
    return defResult
  }
}
