import React, {
  Fragment,
  useContext,
  useCallback,
  useEffect,
  useState,
  useMemo
} from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  IconInfo,
  IconCheckmarkCircle,
  Search,
  Table,
  TreeView,
  Scrollbar,
  ContextualMenu,
  IconXCircle,
  IconPlay
} from '@veneer/core'
import { FlexRow } from 'styles/styles'
import { ColumnIndexTypes } from '@veneer/core/dist/scripts/table/types'
import ConfigContext from 'context/config/configContext'
import CollectionsContext from 'context/devices/CollectionsContext'
import DevicesContext from 'context/devices/DevicesContext'
import {
  getGroupName,
  findPredefinedGroup,
  formatDate,
  DATE_FORMAT,
  NO_DATA,
  RELOAD_EVENT,
  jsonParse
} from 'utils/utilities'
import { getDecoratedName } from 'utils/decoratedName'
import { ComplianceType } from 'utils/deviceHelper'
import { TableSortBy, SortTypes } from '@veneer/core/dist/scripts/table/types'
import GroupsPanel from './groupsPanel'
import './index.scss'
import Retrievei18nItems from 'utils/Retrievei18nItems'

const columnId = 'id'
const columnModelName = 'modelName'
const columnCompliance = 'compliance'
const columnSerialNumber = 'serialNumber'
const columnPolicy = 'policy'
const columnLastRun = 'lastRun'
const defaultOrder = [
  columnModelName,
  columnCompliance,
  columnSerialNumber,
  columnPolicy,
  columnLastRun
]
const defSortOrder: SortTypes = 'descending'
const defComplianceSorting: TableSortBy = {
  id: columnCompliance,
  type: defSortOrder
}
const storageColumns = 'policiesDevicesMfeColumns'
const searchTokenBlue = 'search-token-blue'
const selectedGroup = 'policiesDevicesMfeSelectedGroupId'

const DevicesList = (props) => {
  const { baseurl, navigation, displayToaster, events } = props
  const isWex = props?.themeIdentifier === 'wex'
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const history = navigation || useHistory()
  const cContext = useContext(ConfigContext)
  const { t } = cContext
  const collectionContext = useContext(CollectionsContext)
  const devicesContext = useContext(DevicesContext)
  const { getAllCollection, collections } = collectionContext
  const { getDevicesByCollection, devices, triggerAssessment } = devicesContext

  const [currentCollectionId, setCurrentCollectionId] = useState(null)
  const [currentCollectionName, setCurrentCollectionName] = useState('')

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [searchValue, setSearchValue] = useState('')
  const [order, setOrder] = useState(
    jsonParse(sessionStorage.getItem(storageColumns), defaultOrder)
  )
  const [sorting, setSorting] = useState(defComplianceSorting)

  const getLocalized = (key, param?) =>
    t(key.includes('complianceStatus.') ? key : `devices.table.${key}`, param)

  const getComplianceType = (status, name, search) => {
    const decoratedName = getDecoratedName(name, search, searchTokenBlue)
    const complianceIcon = (status) => {
      switch (status) {
        case ComplianceType.Compliant:
          return (
            <IconCheckmarkCircle
              color={'green7'}
              size={20}
              filled
              customStyle={{ flexShrink: 0 }}
            />
          )
        case ComplianceType.NonCompliant:
          return (
            <IconXCircle
              color={'red7'}
              filled
              size={20}
              customStyle={{ flexShrink: 0 }}
            />
          )
        default: // ComplianceType.Unknown
          return (
            <IconInfo
              color={'gray6'}
              size={20}
              filled
              customStyle={{ flexShrink: 0 }}
            />
          )
      }
    }
    return (
      <div className={'compliance-type'}>
        {complianceIcon(status)}
        <div className={'compliance-name'}>
          {decoratedName.length ? decoratedName : name}
        </div>
      </div>
    )
  }

  const tableData = useMemo(() => {
    const getCompliance = (status, policies) => {
      switch (status) {
        case ComplianceType.Compliant:
          return getLocalized('complianceTypes.compliant')
        case ComplianceType.NonCompliant:
          return getLocalized('complianceTypes.noncompliant', {
            count: policies.filter((x) => x.status === ComplianceType.Compliant)
              .length,
            total: policies.length
          })
        default: // ComplianceType.Unknown
          return getLocalized('complianceTypes.unknown')
      }
    }

    const getPolicy = (policies) => {
      const policyName = (name) => name || getLocalized('deviceSpecific')
      return policies.length > 1
        ? getLocalized('more', {
            name: policyName(policies[0].name),
            count: policies.length - 1
          })
        : policies.length
        ? policyName(policies[0].name)
        : NO_DATA
    }

    const item = (device) => {
      return {
        id: device.deviceId,
        modelName: device.model || NO_DATA,
        status: device.status,
        compliance: getCompliance(device.status, device.policies),
        serialNumber: device.serialNumber || NO_DATA,
        policy: getPolicy(device.policies),
        lastRun: formatDate(device.lastRunAt, DATE_FORMAT.LAST_RUN),
        lastRunAt: device.lastRunAt
      }
    }

    return devices && currentCollectionId
      ? devices.map((device) => item(device))
      : null
  }, [devices, currentCollectionId, t])

  const filteredData = useMemo(() => {
    const searchLowerCase = searchValue.toLowerCase()
    return !tableData
      ? null
      : searchLowerCase.length
      ? tableData.filter(
          (item) =>
            item.modelName.toLowerCase().includes(searchLowerCase) ||
            item.compliance.toLowerCase().includes(searchLowerCase) ||
            item.serialNumber.toLowerCase().includes(searchLowerCase) ||
            item.policy.toLowerCase().includes(searchLowerCase)
        )
      : tableData
  }, [tableData, searchValue])

  const sortedData = useMemo(() => {
    if (!filteredData) {
      return null
    }

    // Sorting
    let picker
    let comparator = (a, b) => (a === b ? 0 : a > b ? 1 : -1)
    switch (sorting.id) {
      case columnLastRun:
        picker = (x) => x.lastRunAt
        break
      case columnModelName:
        picker = (x) => x.modelName
        break
      case columnSerialNumber:
        picker = (x) => x.serialNumber
        break
      default: {
        const complianceTypeKeys: string[] = Object.values(ComplianceType)
        comparator = (a, b) => {
          return a === b
            ? 0
            : complianceTypeKeys.indexOf(a) < complianceTypeKeys.indexOf(b)
            ? 1
            : -1
        }
        picker = (x) => x.status
        break
      }
    }
    return [...filteredData].sort(
      sorting.type === defSortOrder
        ? (a, b) => comparator(picker(a), picker(b))
        : (a, b) => comparator(picker(b), picker(a))
    )
  }, [filteredData, sorting])

  const pagedData = useMemo(() => {
    const getName = (id, name, search) => {
      const decoratedName = getDecoratedName(name, search, 'search-token-gray')
      return (
        <Link
          to={`/${id}`}
          onClick={(e) => {
            e.preventDefault()
            history.push(`${baseurl}/${id}`)
          }}
          id={id}
        >
          {decoratedName.length ? decoratedName : name}
        </Link>
      )
    }

    const getDataForTable = (item, search) => {
      const decoratedSN = getDecoratedName(
        item.serialNumber,
        search,
        searchTokenBlue
      )
      const decoratedPolicy = getDecoratedName(
        item.policy,
        search,
        searchTokenBlue
      )
      const tableItem = {
        id: item.id,
        modelName: getName(item.id, item.modelName, search),
        compliance: getComplianceType(item.status, item.compliance, search),
        serialNumber: decoratedSN.length ? decoratedSN : item.serialNumber,
        policy: decoratedPolicy.length ? decoratedPolicy : item.policy,
        lastRun: item.lastRun
      }
      if (item.policy !== NO_DATA) {
        tableItem['rowConfig'] = {
          action: (
            <ContextualMenu
              id={item.id + '-menu'}
              placement={'bottom-end'}
              options={[
                {
                  value: item.id + 'menu-start',
                  label: getLocalized('complianceStatus.run'),
                  icon: <IconPlay />
                }
              ]}
              onClick={() => onRun(item.id)}
            />
          )
        }
      }
      return tableItem
    }

    // Change current page if required
    const lastPage =
      sortedData && sortedData.length
        ? ~~((sortedData.length - 1) / pageSize)
        : 0
    if (currentPage > lastPage) {
      setCurrentPage(lastPage)
    }

    // Paged
    const searchLowerCase = searchValue.toLowerCase()
    return !sortedData
      ? null
      : [...sortedData]
          .slice(currentPage * pageSize, currentPage * pageSize + pageSize)
          .map((item) => getDataForTable(item, searchLowerCase))
  }, [sortedData, searchValue, pageSize, currentPage, baseurl, history])

  const handlePageChange = (page) => setCurrentPage(page - 1)

  const handlePageSizeChange = (_, option) => setPageSize(option.value)

  const handleOnColumnReorder = (newOrder) => {
    setOrder(newOrder)
    sessionStorage.setItem(storageColumns, JSON.stringify(newOrder))
  }

  const handleSelectedCollectionGroup = (collectionId) => {
    setCurrentCollectionId(collectionId)
    sessionStorage.setItem(selectedGroup, collectionId)
  }

  const onRun = (deviceId) =>
    triggerAssessment(
      (id, message?, type?) =>
        displayToaster(
          id,
          message || getLocalized('complianceStatus.run-success-title'),
          type,
          message ? undefined : getLocalized('complianceStatus.run-success')
        ),
      deviceId
    )

  const collectionNodes = useMemo(() => {
    if (!collections?.contents) {
      return null
    }
    const treeNodes = collections.contents.map((collection) => {
      return {
        id: collection.id,
        label: getGroupName(collection, t),
        totalChildren: collection.devices || null
      }
    })
    const findNode = (group) =>
      group ? treeNodes.find(({ id }) => id === group.id) : null
    const parentNode = findNode(
      findPredefinedGroup(collections.contents, 'All')
    )
    if (parentNode) {
      parentNode.nodes = []
      const ungroupedNode = findNode(
        findPredefinedGroup(collections.contents, 'Ungrouped')
      )
      if (ungroupedNode) {
        parentNode.nodes.push(ungroupedNode)
      }
      parentNode.nodes.push(
        ...treeNodes.filter(
          ({ id }) => id !== parentNode.id && id !== ungroupedNode?.id
        )
      )
      return [parentNode]
    }
    return treeNodes
  }, [collections, t])

  const parentId = useMemo(() => {
    return collectionNodes?.length ? collectionNodes[0].id : null
  }, [collectionNodes])

  useEffect(() => {
    if (!parentId) {
      // currentCollectionId is supposed to be null when parentId is null
      setCurrentCollectionId(null)
    } else {
      const storedNode = sessionStorage.getItem(selectedGroup)
      handleSelectedCollectionGroup(
        collectionNodes
          .find(({ id }) => id === parentId)
          ?.nodes.find(({ id }) => id === storedNode)
          ? storedNode
          : parentId
      )
    }
  }, [parentId, collectionNodes])

  const reload = useCallback(
    () => {
      getAllCollection(displayToaster)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => reload(), [reload])

  useEffect(() => {
    if (events) {
      events.addEventListener(RELOAD_EVENT, reload)
      return () => events.removeEventListener(RELOAD_EVENT, reload)
    }
  }, [events, reload])

  useEffect(() => {
    if (currentCollectionId) {
      getDevicesByCollection(currentCollectionId, displayToaster)
      const currentCollection = collections.contents.find(
        (row) => row.id === currentCollectionId
      )
      setCurrentCollectionName(
        currentCollection ? getGroupName(currentCollection, t) : ''
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCollectionId])

  const tableColumns = useMemo(() => {
    const index: ColumnIndexTypes = 'hidden'
    return [
      {
        id: columnId,
        label: columnId,
        index
      },
      {
        id: columnModelName,
        label: getLocalized('columnHeader.modelName'),
        sortable: true,
        required: true // non-documented
      },
      {
        id: columnCompliance,
        label: getLocalized('columnHeader.compliance'),
        sortable: true
      },
      {
        id: columnSerialNumber,
        label: getLocalized('columnHeader.serialNumber'),
        sortable: true
      },
      {
        id: columnPolicy,
        label: getLocalized('columnHeader.policy'),
        sortable: false
      },
      {
        id: columnLastRun,
        label: getLocalized('columnHeader.lastRun'),
        sortable: true
      }
    ]
  }, [t])

  return (
    <Fragment>
      <FlexRow>
        <GroupsPanel isWex={isWex}>
          <div className={'policy-devices-group-column'}>
            <div className={'policy-devices-group-title'}>
              {t('devices.groups')}
            </div>
            {parentId && (
              <Scrollbar>
                <TreeView
                  defaultExpandedNodes={[parentId]}
                  defaultSelectedNodes={[parentId]}
                  selectedNodes={[currentCollectionId || 0]}
                  onChange={(e, id) => handleSelectedCollectionGroup(id)}
                  nodes={collectionNodes}
                />
              </Scrollbar>
            )}
          </div>
        </GroupsPanel>
        <div className={'main-table'}>
          <div id={'devices'}>
            <Table
              actionArea={
                <Search
                  id={'policy-device-search'}
                  className={'policy-device-search'}
                  placeholder={getLocalized('search', {
                    group: currentCollectionName
                  })}
                  value={searchValue}
                  onChange={(value) => setSearchValue(value)}
                />
              }
              columns={tableColumns}
              data={pagedData || []}
              loading={!pagedData && collectionNodes?.length !== 0}
              loadingDataLength={pageSize}
              onSort={(_, sortBy) => setSorting(sortBy)}
              columnReorder
              onColumnReorder={handleOnColumnReorder}
              pagination={{
                currentPage: currentPage + 1,
                onPageChange: handlePageChange,
                onPageSizeChange: handlePageSizeChange,
                pageSize,
                pageSizeOptions: [
                  { value: 5 },
                  { value: 25 },
                  { value: 50 },
                  { value: 100 },
                  { value: 500 }
                ],
                totalItems: filteredData ? filteredData.length : 0
              }}
              preferences={{
                defaultOrder,
                order,
                sortBy: sorting,
                width: [
                  { columnId: columnModelName, width: 24 },
                  { columnId: columnCompliance, width: 20 },
                  { columnId: columnSerialNumber, width: 12 },
                  { columnId: columnPolicy, width: 24 },
                  { columnId: columnLastRun, width: 20 }
                ]
              }}
              i18n={Retrievei18nItems()}
            />
          </div>
        </div>
      </FlexRow>
    </Fragment>
  )
}

export default DevicesList
