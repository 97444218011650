import {
  Accordion,
  AnchorMenu,
  Card,
  IconCheckmarkCircle,
  IconCircle,
  IconWarningAlt,
  IconInfo,
  IconXCircle,
  Table,
  Button
} from '@veneer/core'
import React, {
  useContext,
  useMemo,
  useEffect,
  useState,
  useCallback
} from 'react'
import { AccordionContent, FlexColumn, FlexRow, SideMenu } from 'styles/styles'
import configContext from 'context/config/configContext'
import DevicesContext from 'context/devices/DevicesContext'
import uuid from 'react-uuid'
import { MicroFrontend } from 'components/MicroFrontend'
import { useHistory } from 'react-router-dom'
import { ComplianceType } from 'utils/deviceHelper'
import { RELOAD_EVENT, NO_DATA, formatDate, DATE_FORMAT } from 'utils/utilities'
import { SortTypes, TableSortBy } from '@veneer/core/dist/scripts/table/types'
import Retrievei18nItems from 'utils/Retrievei18nItems'
import './index.scss'

const columnId = 'id'
const columnSettings = 'settings'
const columnPolicy = 'policy'
const columnCompliance = 'compliance'
const columnReason = 'reason'

const defSortOrder: SortTypes = 'descending'
const defComplianceSorting: TableSortBy = {
  id: columnCompliance,
  type: defSortOrder
}

const reasonUnentitled = 'Feature unentitled'
const reasonUnsupported = 'Feature unsupported'
const statusFailures = ['failure', 'runtime-error']

const DeviceComplianceInfo = (props) => {
  const { t } = useContext(configContext)
  const { getDeviceDetails, deviceData, triggerAssessment } =
    useContext(DevicesContext)
  const {
    navigation,
    displayToaster,
    deviceId,
    baseurl,
    containerId,
    events,
    localization
  } = props
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const history = navigation || useHistory()

  const getLocalized = useCallback(
    (subkey: string): string => {
      return t(`complianceStatus.${subkey}`)
    },
    [t]
  )

  const onRun = (deviceId) =>
    triggerAssessment(
      (id, message?, type?) =>
        displayToaster(
          id,
          message || getLocalized('run-success-title'),
          type,
          message ? undefined : getLocalized('run-success')
        ),
      deviceId
    )

  const [items, setItems] = useState([])
  const [sorting, setSorting] = useState(defComplianceSorting)

  const policiesAccordionContent = (policy) => {
    return (
      <MicroFrontend
        disableContainerPadding
        type={'ecpPolicies'}
        component={'@jarvis/react-ecp-policies'}
        policyCompliance={policy}
        localization={localization}
      />
    )
  }

  const checkIcon = useCallback(
    (status) => {
      let icon
      switch (status) {
        case ComplianceType.NonCompliant:
          icon = (
            <IconXCircle
              filled={true}
              color={'red7'}
              size={20}
              customStyle={{ flexShrink: 0 }}
            />
          )
          break
        case ComplianceType.Compliant:
          icon = (
            <IconCheckmarkCircle
              filled={true}
              color={'green7'}
              size={20}
              customStyle={{ flexShrink: 0 }}
            />
          )
          break
        case ComplianceType.Overridden:
          icon = (
            <IconWarningAlt
              filled={true}
              color={'darkOrange6'}
              size={20}
              customStyle={{ flexShrink: 0 }}
            />
          )
          break
        case ComplianceType.Unknown:
        default:
          icon = (
            <IconInfo
              filled={true}
              color={'gray6'}
              size={20}
              customStyle={{ flexShrink: 0 }}
            />
          )
          break
      }
      return (
        <FlexRow className={'alignCenter'}>
          {icon}
          <p className={'complianceIcon'}>{getLocalized(status)}</p>
        </FlexRow>
      )
    },
    [getLocalized]
  )

  const statusIcon = (status) => {
    let iconColor
    switch (status) {
      case ComplianceType.NonCompliant:
        iconColor = 'red7'
        break
      case ComplianceType.Compliant:
        iconColor = 'green7'
        break
      case ComplianceType.Unknown:
      default:
        iconColor = 'gray6'
        break
    }
    return (
      <FlexRow>
        <div className={'paddingRight8'}>
          <IconCircle size={12} filled={true} color={iconColor} />
        </div>
        <div
          className={'colorGray12'}
          data-testid={'compliance-info-status-name'}
        >
          {getLocalized(deviceData.status)}
        </div>
      </FlexRow>
    )
  }

  const policyName = useCallback(
    (name) => name || t('devices.table.deviceSpecific'),
    [t]
  )
  const policyId = (id) => id || 'id-device-specific'

  const reload = useCallback(() => {
    if (deviceId) {
      getDeviceDetails(displayToaster, deviceId, () => history.push(baseurl))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId, history, baseurl])

  useEffect(() => reload(), [deviceId, reload])

  useEffect(() => {
    if (events) {
      events.addEventListener(RELOAD_EVENT, reload)
      return () => events.removeEventListener(RELOAD_EVENT, reload)
    }
  }, [events, reload])

  useEffect(() => {
    const item = (policy, index) => {
      return {
        id: policyId(policy.policyId),
        content: policiesAccordionContent(policy),
        expanded: false,
        header: {
          id: policyId(policy.policyId),
          centralArea: (
            <div className={'centralAreaAccordion wordBreakWord'}>
              {t('complianceStatus.priorityAndName', {
                priority: index + 1,
                name: policyName(policy.name)
              })}
            </div>
          ),
          endArea: (
            <div className={'paddingRight12'}>{checkIcon(policy.status)}</div>
          )
        }
      }
    }

    setItems(
      deviceData?.policies
        ? deviceData.policies.map((policy, index) => item(policy, index))
        : []
    )
  }, [deviceData, checkIcon, policyName, t])

  const filteredData = useMemo(() => {
    if (!deviceData?.policies) {
      return null
    }

    const reasonText = (item) => {
      if (item.status !== ComplianceType.NonCompliant) {
        return NO_DATA
      }
      const reasons = []
      item.settings.forEach((x) => {
        const reason =
          x.reason === reasonUnsupported
            ? 'unsupported'
            : x.reason === reasonUnentitled
            ? 'unentitled'
            : statusFailures.includes(x.status)
            ? 'unmatched'
            : undefined
        if (reason && !reasons.includes(reason)) {
          reasons.push(reason)
        }
      })
      return reasons.map((x) => getLocalized('reason-' + x)).join(', ')
    }

    const attributes = []
    deviceData.policies.forEach((devicePolicy) => {
      const policyAttributes = devicePolicy.attributes
        .filter((attribute) => attribute.status !== ComplianceType.Overridden)
        .map((item) => {
          return {
            settings: t(`attributes.${item.name}`),
            policy: policyName(devicePolicy.name),
            compliance: checkIcon(item.status),
            reason: reasonText(item),
            status: item.status, // for sorting and counting
            id: uuid
          }
        })
      attributes.push(...policyAttributes)
    })
    return attributes
  }, [deviceData, policyName, checkIcon, getLocalized, t])

  const PolicyNames = useMemo(
    () =>
      deviceData?.policies
        ? deviceData.policies.map((policy) => policyName(policy.name))
        : [],
    [deviceData, policyName]
  )

  const anchorMenuLabels = useMemo(
    () =>
      deviceData?.policies
        ? deviceData.policies.map((policy) => {
            return {
              anchor: policyId(policy.policyId),
              label: policyName(policy.name)
            }
          })
        : [],
    [deviceData, policyName]
  )

  const tableData = useMemo(() => {
    const complianceTypeKeys: string[] = Object.values(ComplianceType)
    const basicComparator = (a, b) => (a === b ? 0 : a < b ? 1 : -1)
    const orderComparator =
      sorting.type === defSortOrder
        ? (a, b) => basicComparator(a, b)
        : (a, b) => basicComparator(b, a)
    const columnComparator = (): ((a, b) => number) => {
      switch (sorting.id) {
        case columnCompliance:
          return (a, b) =>
            orderComparator(
              complianceTypeKeys.indexOf(a.status),
              complianceTypeKeys.indexOf(b.status)
            )
        case columnSettings:
          return (a, b) => orderComparator(a.settings, b.settings)
        case columnReason:
          return (a, b) => orderComparator(a.reason, b.reason)
        case columnPolicy:
        default:
          return (a, b) => orderComparator(a.policy, b.policy)
      }
    }
    return filteredData ? [...filteredData].sort(columnComparator()) : null
  }, [filteredData, sorting])

  const compliantParams = {
    compliantSettings: tableData?.filter(
      (item) => item.status === ComplianceType.Compliant
    ).length
  }
  const nonCompliantParams = {
    nonCompliantSettings: tableData?.filter(
      (item) => item.status === ComplianceType.NonCompliant
    ).length
  }
  const unknownParams = {
    unknownCount: tableData?.filter(
      (item) => item.status === ComplianceType.Unknown
    ).length
  }

  const activatedSettings = () => {
    const settingsArray = []
    if (compliantParams.compliantSettings > 0) {
      settingsArray.push(
        t('complianceStatus.compliantSettingsCount', compliantParams)
      )
    }
    if (nonCompliantParams.nonCompliantSettings > 0) {
      settingsArray.push(
        t('complianceStatus.nonCompliantSettingsCount', nonCompliantParams)
      )
    }
    if (unknownParams.unknownCount > 0) {
      settingsArray.push(t('complianceStatus.unknownCount', unknownParams))
    }
    return settingsArray.join(', ')
  }

  const statusAccordionContent = (
    <AccordionContent>
      <FlexColumn className={'fullWidth'}>
        <FlexColumn className={'paddingBottom8'}>
          <FlexRow className={'paddingBottom4'}>
            <div className={'divWidth'} data-testid={'compliance-info-policy'}>
              {getLocalized('policy')}
            </div>
            <div
              className={'colorGray12 wordBreakWord'}
              data-testid={'compliance-info-policy-name'}
            >
              {PolicyNames.length ? PolicyNames.join(', ') : NO_DATA}
            </div>
          </FlexRow>
          <FlexRow className={'paddingBottom4'}>
            <div className={'divWidth'} data-testid={'compliance-info-lastrun'}>
              {getLocalized('lastRun')}
            </div>
            <div
              className={'colorGray12 wordBreakWord'}
              data-testid={'compliance-info-lastrun-value'}
            >
              {formatDate(deviceData?.lastRunAt, DATE_FORMAT.LAST_RUN)}
            </div>
          </FlexRow>
          <FlexRow className={'paddingBottom4'}>
            <div className={'divWidth'} data-testid={'compliance-info-status'}>
              {getLocalized('status')}
            </div>
            {deviceData?.status && statusIcon(deviceData.status)}
          </FlexRow>
          <FlexRow>
            <div className={'divWidth'} data-testid={'compliance-info-total'}>
              {getLocalized('totalActiveSettings')}
            </div>
            {tableData?.length && (
              <div
                className={'colorGray12'}
                data-testid={'compliance-info-total-counts'}
              >
                {tableData.length} ({activatedSettings()})
              </div>
            )}
          </FlexRow>
        </FlexColumn>
        <Table
          columns={[
            {
              id: columnSettings,
              label: getLocalized(columnSettings),
              sortable: true
            },
            {
              id: columnPolicy,
              label: getLocalized(columnPolicy),
              sortable: true
            },
            {
              id: columnCompliance,
              label: getLocalized(columnCompliance),
              sortable: true
            },
            {
              id: columnReason,
              label: getLocalized(columnReason),
              sortable: true
            },
            {
              id: columnId,
              label: columnId,
              index: 'hidden'
            }
          ]}
          data={tableData || []}
          loading={tableData === null}
          loadingDataLength={25}
          onSort={(_, sortBy) => setSorting(sortBy)}
          preferences={{
            sortBy: sorting,
            width: [
              { columnId: columnSettings, width: 30 },
              { columnId: columnPolicy, width: 30 },
              { columnId: columnCompliance, width: 20 },
              { columnId: columnReason, width: 20 }
            ]
          }}
          i18n={Retrievei18nItems()}
        />
      </FlexColumn>
    </AccordionContent>
  )

  const handleExpand = (event, index) => {
    const updatedData = [...items]
    updatedData[index].expanded = true
    setItems(updatedData)
  }

  const handleCollapse = (event, index) => {
    const updatedData = [...items]
    updatedData[index].expanded = false
    setItems(updatedData)
  }

  return (
    <FlexRow id={'deviceInfo'} style={{ marginTop: '20px' }}>
      <FlexColumn style={{ width: '80%' }}>
        <Card
          id={'complianceStatus'}
          content={
            <Accordion
              className={'rightPaddingAccordion'}
              items={[
                {
                  id: 'complianceInfo',
                  content: statusAccordionContent,
                  expanded: true,
                  header: {
                    centralArea: (
                      <div
                        className={'centralAreaAccordion'}
                        data-testid={'compliance-info-title'}
                      >
                        {getLocalized('title')}
                      </div>
                    ),
                    endArea: deviceData?.policies.length ? (
                      <div className={'paddingRight12'}>
                        <Button
                          appearance={'secondary'}
                          data-testid={'compliance-info-run-button'}
                          onClick={() => {
                            onRun(deviceData?.deviceId)
                          }}
                        >
                          {getLocalized('run')}
                        </Button>
                      </div>
                    ) : undefined
                  }
                }
              ]}
              behavior={'singleExpand'}
              id={'status-accordion'}
            />
          }
          appearance={'dropShadow'}
          customStyle={{ marginBottom: '16px' }}
        />
        <Accordion
          className={'rightPaddingAccordion noPaddingAccordion'}
          gutter={16}
          items={items}
          behavior={'multiExpand'}
          id={'policy-accordion'}
          border={'dropShadow'}
          onExpand={handleExpand}
          onCollapse={handleCollapse}
        />
      </FlexColumn>
      <SideMenu style={{ width: '20%' }}>
        <AnchorMenu
          containerId={containerId}
          id={'device-compliance-anchor'}
          position={{
            position: 'relative',
            end: 0,
            start: 24
          }}
          items={[
            {
              anchor: 'complianceStatus',
              label: getLocalized('title')
            },
            ...anchorMenuLabels
          ]}
        />
      </SideMenu>
    </FlexRow>
  )
}
export default DeviceComplianceInfo
