import { DevicesActions } from 'context/types'

export default (state, action) => {
  switch (action.type) {
    case DevicesActions.GET_ALL_DEVICES:
      return {
        ...state,
        devices: action.payload
      }
    case DevicesActions.GET_DEVICE_DATA:
      return {
        ...state,
        deviceData: action.payload
      }
    default:
      return state
  }
}
