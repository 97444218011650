import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { MicroFrontend } from 'components/MicroFrontend'
import DeviceComplianceInfo from '../deviceComplianceInfo/index'

const DeviceDetails = (props) => {
  const { deviceId } = useParams()

  const mfe = useMemo(
    () => (
      <MicroFrontend
        disableContainerPadding
        type={'PoliciesDevicesDevices'}
        component={'@jarvis/react-ecp-device-details'}
        {...props}
        showDetailsTab={false}
        showLastUpdatedSection={false}
        deviceID={deviceId}
        ecpDeviceV55={true}
      />
    ),
    [deviceId]
  )

  return (
    <div id="policies-devices">
      {mfe}
      <DeviceComplianceInfo {...props} deviceId={deviceId} />
    </div>
  )
}

export default DeviceDetails
